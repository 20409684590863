/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    InstallPrompt as SourceInstallPrompt
} from 'SourceComponent/InstallPrompt/InstallPrompt.component';

import './InstallPrompt.style';

/** @namespace Scandipwa/Component/InstallPrompt/Component */
export class InstallPromptComponent extends SourceInstallPrompt {
    render() {
        const { isBannerClosed } = this.props;

        if (isBannerClosed) {
            return null;
        }

        return (
            <div block="InstallPrompt">
                { this.renderPrompt() }
            </div>
        );
    }
}

export default InstallPromptComponent;

import { Suspense } from 'react';
import { connect } from 'react-redux';

import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import { lowPriorityLazy } from 'Util/Request/LowPriorityLoad';

import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps,
    mapStateToProps
} from './HomePage.container.source';

import './HomePage.override.style';

export const InstallPrompt = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "install-prompt" */
    'Component/InstallPrompt'
));

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            isInstallPromptAvailable: window.isInstallPromptAvailable
        };
    }

    componentDidMount() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
            title: __('Your outdoor Sanctuary Reimagined')
        });
    }

    renderInstallPromptFallback() {
        const { device } = this.props;

        return (
            <div block="HomePage" elem="InstallPromptFallback">
                <div block="HomePage" elem="InstallPromptFallbackBox" mods={ { isAndroid: device.android } } />
            </div>
        );
    }

    renderInstallPrompt() {
        const { isInstallPromptAvailable } = this.state;

        if (!isInstallPromptAvailable) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderInstallPromptFallback() }>
                <InstallPrompt />
            </Suspense>
        );
    }

    /**
     * Overridden to remove Footer component as it gets rendered from Router component
     */
    render() {
        return (
            <div block="HomePage">
                { this.renderInstallPrompt() }
                <CmsPage { ...this.containerProps() } />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);

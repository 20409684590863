import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

/** @namespace Scandiweb/SeoSuite/Plugin/HPStructuredData/injectScriptToComponentDidMount */
export const injectScriptToComponentDidMount = async (args, callback) => waitForPriorityLoad().then(
    async () => {
        const { initHPScript } = await import(
            /* webpackMode: "lazy", webpackChunkName: "hp-script" */
            '../util/HPStructuredScript'
        );

        await initHPScript();

        return callback(...args);
    }
);

export const removeScriptToComponentDidMount = async (args, callback) => waitForPriorityLoad().then(
    async () => {
        const { removeHPScript } = await import(
            /* webpackMode: "lazy", webpackChunkName: "hp-script" */
            '../util/HPStructuredScript'
        );

        await removeHPScript();

        return callback(...args);
    }
);

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount: injectScriptToComponentDidMount,
            componentWillUnmount: removeScriptToComponentDidMount
        }
    }
};

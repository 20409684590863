import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { MENU } from 'Component/Header/Header.config';
import { scrollToTop } from 'Util/Browser';

import {
    mapDispatchToProps,
    mapStateToProps,
    MenuPageContainer as SourceMenuPageContainer
} from './MenuPage.container.source';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/MenuPage/Container */
export class MenuPageContainer extends SourceMenuPageContainer {
    /**
     * Overridden to fix back() function. Previously "goBack()"
     * onBackClick replaced for onCloseClick
     */
    componentDidMount() {
        const { updateMeta, changeHeaderState } = this.props;

        scrollToTop();

        updateMeta({ title: __('Menu') });
        this.redirectIfNotOnMobile();

        changeHeaderState({
            name: MENU,
            onCloseClick: () => history.back()
        });
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuPageContainer)
);
